import styled from 'styled-components'
import { themeV2 as theme } from '@taglivros/tag-components'

export const CaptchaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border: solid 1px ${theme.palette[ 'TAG1-100' ]};
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
`
