import subscriptionSlice from '@one-checkout/store/subscription.slice'
import { getSubscriptionByEmail, getSubscriptionByCpf } from '@one-checkout/services/subscription.service'
import store from '@one-checkout/store'

const NOT_FOUND_STATUS_CODE = 404

export function setSubscription( subscription: any ) {
  store.dispatch( subscriptionSlice.actions.setSubscription( subscription ) )
}

export function setIsSubscriber( isSubscriber: boolean | null ) {
  store.dispatch( subscriptionSlice.actions.setIsSubscriber( isSubscriber ) )
}

export function setStatus( status: subscriptionSlice.Status | null ) {
  store.dispatch( subscriptionSlice.actions.setStatus( status ) )
}

export function setDiscount( discount: number ) {
  store.dispatch( subscriptionSlice.actions.setDiscount( discount ) )
}

export async function verifySubscriptionStatusByEmail( email: string ) {
  try {
    const { statusAssinatura, descontoAssinatura } = ( await getSubscriptionByEmail( email ) ).data
    if ( statusAssinatura ) {
      store.dispatch( subscriptionSlice.actions.setStatus( statusAssinatura ) )
      store.dispatch( subscriptionSlice.actions.setIsSubscriber( true ) )
    } else {
      store.dispatch( subscriptionSlice.actions.setStatus( '' ) )
      store.dispatch( subscriptionSlice.actions.setIsSubscriber( false ) )
      const { product } = store.getState()
      if ( !product?.isSubscription && descontoAssinatura ) {
        if ( descontoAssinatura[ 0 ].tipoDesconto === 'TOTAL' && !descontoAssinatura[ 0 ].percentual ) {
          setDiscount( descontoAssinatura[ 0 ].valor )
        }
      } else {
        setDiscount( 0 )
      }
    }
  } catch ( error: any ) {
    if ( error.response?.status === NOT_FOUND_STATUS_CODE ) {
      store.dispatch( subscriptionSlice.actions.setStatus( '' ) )
      store.dispatch( subscriptionSlice.actions.setIsSubscriber( false ) )
      setDiscount( 0 )
      return
    }
    throw error
  }
}

export async function verifySubscriptionStatusByCpf( cpf: string ) {
  try {
    const { statusAssinatura, emailAssociado } = ( await getSubscriptionByCpf( cpf ) ).data
    if ( statusAssinatura ) {
      store.dispatch( subscriptionSlice.actions.setStatus( statusAssinatura ) )
      store.dispatch( subscriptionSlice.actions.setEmail( emailAssociado ) )
      store.dispatch( subscriptionSlice.actions.setIsSubscriber( true ) )
    }
    return true
  } catch ( error: any ) {
    if ( error.response.status === NOT_FOUND_STATUS_CODE ) {
      return false
    }
    throw error
  }
}
