import { generateLeadEvent } from '@one-checkout/actions/event.action'
import { createClubLead, postLeadToHermesClient, postLeadEventToHermesClient } from '@one-checkout/services/lead.service'
import store from '@one-checkout/store'
import leadSlice from '@one-checkout/store/lead.slice'

export async function registerLead( name: string, email: string, phone: string ): Promise<void> {
  const { product } = store.getState()
  try {
    generateLeadEvent( 'request' )

    if ( product!.type === 'trail' ) {
      postLeadToHermesClient( email, name, phone, getTrailLeadOrigin( product!.name ) )
    } else {
      const { categoryId, planId } = product!

      postLeadToHermesClient( email, name, phone, getClubLeadOrigin( product!.name, planId! ) )
      await createClubLead( email, categoryId!, planId! ).catch( () => undefined )
    }

    generateLeadEvent( 'success' )

    const { status } = store.getState().subscription!
    const leadStatus = status === 'ATIVO' ? 'active' : 'inactive'

    setLeadStatus( leadStatus )
  } catch ( error: any ) {
    generateLeadEvent( 'error' )
    throw ( error )
  }
}

export function leadEventFillEmail( email: string, productName: string ): void {
  const eventIdentifier = 'checkout_' + productName + '_email'
  postLeadEventToHermesClient( eventIdentifier, email )
}

export function leadEventFillPostalCode( email: string, productName: string ): void {
  const eventIdentifier = 'checkout_' + productName + '_cep'
  postLeadEventToHermesClient( eventIdentifier, email )
}

export function leadEventFillDocument( email: string, productName: string ): void {
  const eventIdentifier = 'checkout_' + productName + '_cpf'
  postLeadEventToHermesClient( eventIdentifier, email )
}

export function leadEventTryToPay( email: string, productName: string ): void {
  const eventIdentifier = 'checkout_' + productName + '_clicou_em_pagar'
  postLeadEventToHermesClient( eventIdentifier, email )
}

export function leadEventErrorOnPayment( email: string, productName: string ): void {
  const eventIdentifier = 'checkout_' + productName + '_erro_pagamento'
  postLeadEventToHermesClient( eventIdentifier, email )
}

export function setLeadStatus( leadStatus: leadSlice.LeadStatus ) {
  store.dispatch( leadSlice.actions.setLeadStatus( leadStatus ) )
}

function getTrailLeadOrigin( productName: string ): string {
  return 'origem_checkout_' + productName.toLowerCase()
}

function getClubLeadOrigin( productName: string, planId: number ): string {
  if ( productName.startsWith( 'experience' ) ) {
    return 'origem_checkout_experimente'
  }

  const planOriginsDict: Record<string, string> = {
    '1': 'origem_checkout_curadoria_mensal',
    '3': 'origem_checkout_curadoria_anual',
    '4': 'origem_checkout_ineditos_mensal',
    '6': 'origem_checkout_ineditos_anual'
  }

  return planOriginsDict[ planId ] || 'origem_checkout_nao_mapeado'
}
