import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const paymentSlice = createSlice( {
  'name': 'payment',
  'initialState': {
    'method': 'card',
    'documentNumber': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'cardNumber': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'expiry': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'cvv': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'nameOnCard': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'installments': {
      'isValid': true,
      'helperText': '',
      'disabled': false,
    },
    'pixData': {
      'code': '',
      'url': '',
      'expiryDate': new Date().toString(),
      'valid': false,
      'hash': '',
    },
    'errorReason': '',
    'error': false,
    'isPaying': false,
    'redirectToken': '',
    'captcha': '',
  } as paymentSlice.State,
  'reducers': {
    setPaymentMethod( state, action: PayloadAction<paymentSlice.SetPaymentMethodPayload> ) {
      state.method = action.payload
    },
    setPaymentFields( state, action: PayloadAction<paymentSlice.SetPaymentFieldsPayload> ) {
      Object.assign( state, action.payload )
    },
    setPaymentError( state, action: PayloadAction<paymentSlice.SetPaymentErrorPayload> ) {
      state.error = action.payload
    },
    setPaymentErrorReason( state, action: PayloadAction<paymentSlice.SetPaymentErrorReasonPayload> ) {
      state.errorReason = action.payload
    },
    setPixData( state, action: PayloadAction<paymentSlice.SetPixDataPayload> ) {
      state.pixData = action.payload
    },
    expiryPix( state ) {
      state.pixData.valid = false
    },
    setIsPaying( state, action: PayloadAction<paymentSlice.SetIsPayingPayload> ) {
      state.isPaying = action.payload
    },
    setRedirectToken( state, action: PayloadAction<paymentSlice.SetRedirectTokenPayload> ) {
      state.redirectToken = action.payload
    },
    resetDocumentNumber( state ) {
      state.documentNumber = paymentSlice.getInitialState().documentNumber
    },
    setCaptcha( state, action: PayloadAction<paymentSlice.SetCaptchaPayload> ) {
      state.captcha = action.payload
    },
  }
} )
namespace paymentSlice {
  export interface State {
    method?: Method,
    documentNumber: Field
    cardNumber: Field
    expiry: Field
    cvv: Field
    nameOnCard: Field
    installments: Field
    pixData: PixData
    error: boolean
    errorReason: string
    isPaying: boolean
    redirectToken: string
    captcha: string
  }

  export type Method = 'pix' | 'card'

  export interface Field {
    value: string
    isValid: boolean
    helperText: string
    disabled: boolean
  }

  export interface PixData {
    code: string
    url: string
    expiryDate: string
    valid: boolean
    hash: string
  }

  export type SetPaymentMethodPayload = Method | undefined
  export type SetPaymentFieldsPayload = Partial<Pick<paymentSlice.State, 'documentNumber' | 'cardNumber' | 'expiry' | 'cvv' | 'nameOnCard' | 'installments'>>
  export type SetPaymentErrorPayload = boolean
  export type SetPaymentErrorReasonPayload = string
  export type SetPixDataPayload = PixData
  export type SetIsPayingPayload = boolean
  export type SetRedirectTokenPayload = string
  export type SetCaptchaPayload = string
}

export default paymentSlice
