/* eslint-disable max-lines */
import ControllerView from '@one-checkout/components/ControllerView'
import React, { useEffect, useState } from 'react'
import { graphql, PageProps, navigate } from 'gatsby'
import CommonHead from '@shared/meta/CommonHead'
import { RECAPTCHA_SCRIPT_SRC } from '@shared/services/validateCaptcha'
import { Provider } from 'react-redux'
import store from '@one-checkout/store'
import Showcase from '@one-checkout/interfaces/Showcase'
import Product from '@shared/interfaces/Product'
import { ImageDataLike } from 'gatsby-plugin-image'
import { getRedirectURLForProductName } from '@one-checkout/utils/redirects'
import { MaintenanceView } from '@one-checkout/components/MaintenanceView/MaintenanceView'
import { getToggle } from '@one-checkout/services/toggle'

export function Head() {
  const { productName } = getPropsFromQuery()
  const redirectUrl = getRedirectURLForProductName( productName )

  return (
    <CommonHead title="Checkout TAG">
      {
        redirectUrl && (
          <meta
            httpEquiv="refresh"
            content={ `0; URL=${redirectUrl}` }
          />
        )
      }
      <script
        src={ `${RECAPTCHA_SCRIPT_SRC}?render=explicit` }
        async
        defer
      />
    </CommonHead>
  )
}

function OnePageCheckout( { data }: OnePageCheckout.Props ) {
  const { productName, discountCode } = getPropsFromQuery()
  const definitions = extractDefinitionsFromQLNodes( data.allStrapiCheckoutDefinitions )
  const product = definitions.find( definition => definition.name === productName )
  const [ atMaintenance, setAtMaintenance ] = useState<boolean>()
  const [ isLoading, setIsLoading ] = useState<boolean>( true )

  const verifyMaintenance = async() => {
    const maintenance = await getToggle( 'maintenance_checkout' )
    setAtMaintenance( maintenance )
    setIsLoading( false )
  }

  const verifyCheckoutEnabled = async() => {
    const checkoutEnabled = await getToggle( 'checkout_' + productName )
    if ( !checkoutEnabled ) {
      navigate( '/' )
    }
    return null
  }

  useEffect( () => {
    verifyMaintenance()
    verifyCheckoutEnabled()
  } )

  if ( typeof window === 'undefined' || isLoading ) {
    return null
  }

  if ( !product ) {
    navigate( '/' )
    return null
  }

  if ( atMaintenance || atMaintenance === null ) {
    return <MaintenanceView />
  }

  return (
    <Provider store={ store }>
      <ControllerView
        product={ product! }
        promotionCouponCode={ discountCode }
        verifyMaintenance={ verifyMaintenance }
      />
    </Provider>
  )
}
namespace OnePageCheckout {
  export type Props = PageProps<DataType>

  export interface DataType {
    allStrapiCheckoutDefinitions: {
      edges: [
        {
          node: QLDefinitions
        }
      ]
    }
  }

  export interface QLImage {
    localFile: {
      childImageSharp: {
        gatsbyImageData: ImageDataLike
      }
    }
  }

  export type QLDefinitions = {
    productName: string
    type: 'club' | 'oneTimeOffer' | 'trail' | 'experience'
    description: string
    title: string
    discount: number
    enableMemberDiscount?: boolean
    price: number
    redirectToAda: boolean
    categoryId?: number
    planId?: number
    isSubscription: boolean
    isFreeShipping?: boolean
    hideShippingInfo?: boolean
    displayPromotionCoupon?: boolean
    availableForNonSubscribers: boolean
    totalSummaryCtaDescription?: string
    memberDiscount?: number
    shippingFrom?: string
    productImage: QLImage
    paymentMethodDetail: QLPaymentMethodDetail[]
    gift?: QLGift
    mimo?: QLGift
    conclusion?: string
    enableSaleGift?: boolean
  }

  export type QLPaymentMethodDetail = {
    methodType: 'creditCard' | 'pix'
    percentageDiscount: number
    defaultInstallment?: number
    installmentsOptions?: string
  }

  export type QLGift = {
    title: string
    text: string
    thumbnailImage: QLImage
    zoomImage: QLImage
    description: string
  }
}

function getPropsFromQuery() {
  if ( typeof location === 'undefined' ) {
    return {}
  }

  const query = new URLSearchParams( location.search )
  const productName = query.get( 'product' ) as keyof Showcase || undefined
  const discountCode = query.get( 'coupon' ) || query.get( 'cupom' ) || undefined

  if ( !productName ) {
    const category = query.get( 'categoria' ) || undefined
    const modality = query.get( 'modalidade' ) || undefined

    if ( category && modality ) {
      const productNameList = [
        {
          'category': 'curadoria',
          'modality': 'anual',
          'productName': 'yearlyCuradoria',
        },
        {
          'category': 'curadoria',
          'modality': 'mensal',
          'productName': 'monthlyCuradoria',
        },
        {
          'category': 'ineditos',
          'modality': 'anual',
          'productName': 'yearlyIneditos',
        },
        {
          'category': 'ineditos',
          'modality': 'mensal',
          'productName': 'monthlyIneditos',
        }
      ]

      const productNameByCategoryAndModality = productNameList.find( x => x.category === category && x.modality === modality )

      if ( productNameByCategoryAndModality ) {
        return {
          'productName': productNameByCategoryAndModality.productName as keyof Showcase,
          discountCode
        }
      }
    }
  }

  return { productName, discountCode }
}

function extractDefinitionsFromQLNodes( nodesDefinitions: { edges: [ { node: OnePageCheckout.QLDefinitions } ] } ): Product[] {
  return nodesDefinitions.edges.map( definition => {
    const {
      paymentMethodDetail,
      gift,
      mimo
    } = definition.node

    const extractGiftOrMimo = ( giftOrMimo?: OnePageCheckout.QLGift ): any => {
      if ( giftOrMimo ) {
        return {
          ...giftOrMimo,
          'thumbnailImage': giftOrMimo.thumbnailImage.localFile.childImageSharp.gatsbyImageData,
          'zoomImage': giftOrMimo.zoomImage.localFile.childImageSharp.gatsbyImageData,
        }
      }
      return {}
    }

    const extractPaymentMethodDetail = (): any => {
      if ( paymentMethodDetail ) {
        return paymentMethodDetail.map( detail => {
          return {
            'methodType': detail.methodType,
            'percentageDiscount': detail.percentageDiscount,
            'defaultInstallment': detail.defaultInstallment,
            'installmentsOptions': detail.installmentsOptions?.split( ',' )
          }
        } )
      }
      return []
    }

    return {
      'type': definition.node.type,
      'name': definition.node.productName,
      'title': definition.node.title,
      'description': definition.node.description,
      'totalSummaryCtaDescription': definition.node.totalSummaryCtaDescription,
      'discount': definition.node.discount,
      'enableMemberDiscount': definition.node.enableMemberDiscount,
      'memberDiscount': definition.node.memberDiscount,
      'price': definition.node.price,
      'isSubscription': definition.node.isSubscription,
      'isFreeShipping': definition.node.isFreeShipping,
      'hideShippingInfo': definition.node.hideShippingInfo,
      'redirectToAda': definition.node.redirectToAda,
      'categoryId': definition.node.categoryId,
      'planId': definition.node.planId,
      'shippingFrom': definition.node.shippingFrom,
      'displayPromotionCoupon': definition.node.displayPromotionCoupon,
      'availableForNonSubscribers': definition.node.availableForNonSubscribers,
      'paymentMethodsDetail': extractPaymentMethodDetail(),
      'productImage': definition.node.productImage.localFile.childImageSharp.gatsbyImageData,
      'gift': extractGiftOrMimo( gift ),
      'mimo': extractGiftOrMimo( mimo ),
      'conclusion': definition.node.conclusion,
      'enableSaleGift': definition.node.enableSaleGift
    }
  } )
}

export default OnePageCheckout

export const query = graphql`
  query {
    allStrapiCheckoutDefinitions {
      edges {
        node {
          productName
          type
          description
          title
          discount
          enableMemberDiscount
          price
          redirectToAda
          categoryId
          planId
          isSubscription
          isFreeShipping
          hideShippingInfo
          displayPromotionCoupon
          availableForNonSubscribers
          totalSummaryCtaDescription
          memberDiscount
          shippingFrom
          productImage {
            localFile {
              childImageSharp {
                gatsbyImageData (
                  width: 200,
                  placeholder: BLURRED,
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          paymentMethodDetail {
            methodType
            percentageDiscount
            defaultInstallment
            installmentsOptions
          }
          gift {
            title
            text
            thumbnailImage {
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    width: 200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            zoomImage {
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            description
          }
          mimo {
            title
            text
            thumbnailImage {
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    width: 200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            zoomImage {
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            description
          }
          conclusion
          enableSaleGift
        }
      }
    }
  }
`
