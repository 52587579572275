const RECAPTCHA_ENABLED = process.env[ 'GATSBY_RECAPTCHA_ENABLED' ] === 'true'
const RECAPTCHA_SITE_KEY = process.env[ 'GATSBY_RECAPTCHA_SITE_KEY' ]
const RECAPTCHA_SCRIPT_SRC = 'https://www.google.com/recaptcha/enterprise.js'

export default function validateCaptcha(): string {
  if ( !RECAPTCHA_ENABLED ) {
    return ''
  }

  const captchaScript = document.head.querySelector( `script[src^="${RECAPTCHA_SCRIPT_SRC}"]` )

  if ( !captchaScript ) {
    throw new Error( 'Captcha script not loaded' )
  }

  const { grecaptcha } = window as any
  const grecaptchaResponse = grecaptcha.enterprise.getResponse()

  return grecaptchaResponse
}

export { RECAPTCHA_SITE_KEY, RECAPTCHA_SCRIPT_SRC }
