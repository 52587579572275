import { formatPrice } from '@checkout/utils/formatter'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import React from 'react'
import { Step } from '../ControllerView/setup'
import * as S from './styles'
import { ReactComponent as Loader } from '../../assets/images/loader.svg'

function TotalSummary( props: TotalSummary.Props ) {
  const {
    concludedStep,
    currentStep,
    disabled,
    discount,
    installments,
    isFreeShipping,
    hideShippingInfo,
    purchaseActionText,
    productPrice,
    shipping,
    showLoader,
    totalSummaryCtaDescription,
    onContinue
  } = props

  const oldPrice = discount ? productPrice + ( shipping || 0 ) : null
  const newPrice = productPrice + ( shipping || 0 ) - discount
  const amountByInstallment = newPrice / installments

  function shouldDisplayCtaDescription() {
    const stepsToShow: Step[] = [
      'payment',
      'payment/pix',
      'payment/card'
    ]
    return stepsToShow.some( ( step ) => step === currentStep ) && totalSummaryCtaDescription
  }

  function displayShippingInfo() {
    if ( hideShippingInfo ) {
      return null
    }
    return (
      <>
        {!shipping && !isFreeShipping && (
          <Typography
            className="shipping"
            color="TAG1-700"
            variant="text-small-regular"
          >
            {shipping === undefined ? '+ taxa de entrega' : 'frete grátis'}
          </Typography>
        )}
        {!shipping && isFreeShipping && (
          <Typography
            className="shipping"
            color="TAG1-700"
            variant="text-small-regular"
          >
            frete grátis
          </Typography>
        )}
      </>
    )
  }

  return <S.Container className={ currentStep.replace( /\//g, '-' ) }>
    <S.Price>
      {oldPrice && (
        <Typography
          className="old-price"
          color="TAG1-700"
          variant="text-small-regular"
        >
            de <del>{formatPrice( oldPrice, true )}</del>
        </Typography>
      )}
      {installments > 1 && (
        <Typography
          className="new-price"
          color="TAG1-700"
          variant="text-small-regular"
        >
          {oldPrice && 'por '}{formatPrice( newPrice, true )}
        </Typography>
      )}
      <div className="final-price">
        {installments > 1 && (
          <Typography
            color="TAG1-700"
            variant="text-medium-bold"
          >
            {installments}x
          </Typography>
        )}
        <Typography
          color="TAG1-700"
          variant="title-medium-bold"
        >
          {formatPrice( amountByInstallment, true )}
        </Typography>
      </div>
      { displayShippingInfo() }
    </S.Price>

    {currentStep !== 'payment/pix/confirmation' && (
      <S.CTA
        className={ concludedStep?.replace( /\//g, '-' ) }
        disabled={ disabled }
        onClick={ () => onContinue() }
      >
        {!showLoader ? (
          <Typography
            color="WHITE"
            variant="button-medium-bold"
          >
            {concludedStep === 'payment' ? purchaseActionText : 'Continuar' }
          </Typography>
        ) : (
          <Loader />
        )}
      </S.CTA>
    )}

    {shouldDisplayCtaDescription() && (
      <Typography
        color="TAG1-700"
        variant="text-small-bold"
      >
        {totalSummaryCtaDescription}
      </Typography>
    )}
  </S.Container>
}
namespace TotalSummary {
  export interface Props {
    concludedStep?: Step
    currentStep: Step
    disabled: boolean
    discount: number
    installments: number
    isFreeShipping: boolean
    hideShippingInfo?: boolean
    productPrice: number
    purchaseActionText: string
    shipping?: number
    showLoader: boolean
    totalSummaryCtaDescription?: string
    onContinue(): void
  }
}

export default TotalSummary
